<template>
  <div class="product-dev">
    <div class="product-dev-tag">
      <span :class="{'active':productType == 'dev'}" @click="productTag('dev')">开发板</span>
      <span :class="{'active':productType == 'soc'}" @click="productTag('soc')">SOC</span>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-if="productList.length>0">
      <div class="product-dev-list-box">
        <div class="product-iot-list" v-for="el in productList" :key="el.index" @click="productInfo(el.id)">
          <div class="product-iot-list-img">
            <v-img  :attachment-id="el.coverAttmId" :default-img="require('@/assets/img/kctp.png')" />
          </div>
          <div class="product-iot-list-info">
            <strong>{{el.name}}</strong>
            <span v-if="el.datSocs">SOC：<template v-for="i in el.datSocs">{{i.name}}</template></span>
            <span v-if="productType == 'soc'">型号：{{el.modelNum || '/'}}</span>
            <span>厂商：{{el.isvName}}</span>
            <p v-if="el.sceneTagName">
              <template v-for="(e,i) in el.sceneTagName.split(',')">
                <span v-if="i < 2" :key="i">{{e}}</span>
              </template>
            </p>
          </div>
        </div>
      </div>
    </van-list>

    <van-empty
      v-if="emptybool"
      class="custom-image"
      :image="nonecontent"
      description="暂无内容"
    />
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getIotSocList,getIotDevList,getProductList } from "@/api/iot";
import VImg from "@/components/VImg";
import nonecon from '@/assets/img/nonecontent1.png'
import share from '../components/share.vue'
export default {
  components:{
    VImg,
    share
  },
  data() {
    return {
      nonecontent:nonecon,
      productList:[],
      page:{
        current: 1,
        size: 9,
        pageSize: 1,
      },
      productType:'dev',
      loading: false,
      finished: false,
      emptybool:false,
      shareInfo: {
        title: '享｜开发资源',
        desc: '基于OpenHarmony聚焦智能物联网的技术与持续创新。专注于行业开发板、模组等配套开发资源的多场景应用，鸿联联创平台致力于为您提供免费的开发资源，助力您的国产替代迁移需求低成本实现，赋能千行百业。',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553280480000921602`
      }
    };
  },
  mounted(){
    document.title = '享｜开发资源'
    if(this.$route.query.type){
      this.productType = this.$route.query.type
    }
    this.loadProductList();
  },
  methods:{
    productTag(type){
      this.finished = false;
      this.productType = type
      this.productList = []
      this.page.current = 1
      this.loadProductList();
    },
    onLoad(){
      if(this.productList == ''){
        return
      }
      if(this.page.pageSize == this.page.current){
        this.finished = true;
        return
      }
      this.page.current ++
      this.loading = true
      this.loadProductList();
    },
    loadProductList(){
      if(this.productType == 'dev'){
        this.loadgetIotDevList()
      }else if(this.productType == 'soc'){
        this.loadgetIotSocList()
      }
    },
    // SOC列表
    loadgetIotSocList(){
      getProductList(this.page,{
        status: '2',
        categoryId:'',
        type:'soc',
      }).then((res)=>{
        this.loading = false;
        this.productList = [...this.productList,...res.records]
        this.page.pageSize = res.pages;
        // if(res.pages <= res.current){
        //   this.finished = true;
        //   return
        // }
        this.finished = false;

        if(this.productList.length==0){
          this.emptybool=true
        }else {
          this.emptybool=false
        }
      })
    },
    // 开发板列表
    loadgetIotDevList(){
      getProductList(this.page,{
        status: '2',
        categoryId:'',
        type:'dev',
      }).then((res)=>{
        this.loading = false;
        this.productList = [...this.productList,...res.records]
        this.page.pageSize = res.pages;
        if(res.pages <= res.current){
          this.finished = true;
          return
        }
        this.finished = false;
      })
    },
    productInfo(id){
      let {productType} = this
      this.$router.push({
        path: "/product/productInfo",
        query: { id,type:productType },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-image{
  ::v-deep{
    .van-empty__image{
      width: 114px;
      height: 74.09px;
    }
    .van-empty__description{
      font-size: 14px;
      font-weight: 500;
      line-height: 46px;
      color: #999999;
    }
  }
}
.product-dev{
    background: #F6F6F6;
    min-height: calc(100vh - 56px);
    .product-dev-tag{
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #ddd;
        >span{
            height: 48px;
            line-height: 48px;
            margin-right: 55px;
            font-size: 16px;
            color: #000;
            border-bottom: 3px solid transparent;
            &:last-child{
                margin-right: 0;
            }
            &.active{
                border-color: #EA0B06;
                color: #EA0B06;
            }
        }
    }
}
.product-dev-list-box {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .product-iot-list {
    width: 167px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 9px;
    .product-iot-list-img {
      position: relative;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      > span {
        position: absolute;
        width: 32px;
        height: 19px;
        left: 10px;
        top: -4px;
        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      > img {
        display: block;
        // border-top-left-radius: 4px;
        // border-top-right-radius: 4px;
        width: 90px;
      }
    }
    .product-iot-list-info {
      padding: 8px;
      > strong {
        display: block;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #333333;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
        display: block;
        margin-bottom: 5px;
      }
      > p {
        display: flex;
        margin-bottom: 0;
        > span {
          height: 19px;
          background: #f7f7f7;
          border-radius: 2px;
          padding: 0 6px;
          font-size: 12px;
          font-weight: 400;
          line-height: 19px;
          color: #666;
          padding: 0 6px;
          margin-right: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
